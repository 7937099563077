@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.input-container {
    .title {
        margin: 0 0 15px 0;
        font-weight: 500;
        font-size: 14px;
    }
    

    .title-relative {
        display: grid;
        grid-template-columns: auto 10px 1fr;

        .required-container {
            position: relative;
        }
    }

    .input {
        padding: 15px;
        width: 100%;
        border: 1px solid #d6d6d6;
        font-family: $font;
        letter-spacing: 1px;
        -webkit-font-smoothing: antialiased;

        &:focus {
            border: 1px solid $color-secondary;
        }
    }

    .error {
        @extend .input;
        border: 1px solid $color-error;
    }

    .error-title {
        color: $color-error;
        margin: 10px 0;
        font-weight: 400;
        font-size: 13px;
    }
}
