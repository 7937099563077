@import "../../../App.scss";

.spinner-container {
    position: relative;

    .custom-spinner {
        @extend .spinner-large;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-50%, -50%);
    }
}
