@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.switch-container.badge {
    background-color: #E8DDFF !important;
    padding: 5px;

    & .btn.size-tiny {
        width: 76px;
        height: 20px;
        text-transform: capitalize;
        padding: 0px !important;
        background-color: #E8DDFF;
        background: none;
        color: $color-secondary;
        transition: .2s;

        &.active {
            background-color: $color-secondary;
            color: #fff;
        }

        &:hover {
            background-color: #E8DDFF;
            &.active {
                background-color: $color-secondary;
                color: #fff;
            }
        }
    }
}

.btn.size-small.transparent-background {
    background-color: transparent;
    color: $color-secondary;
    padding: 0 10px;

    &:hover, &:active, &:focus {
        background-color: transparent;
    }
    .notification-area {
        margin: 0 20px;
        & .notification-icon {
            color: $color-secondary;
        }
    
        & .notification-badge {
            background-color: $color-checkbox;
        }
    }
}

.setting-container {
    position: relative;

    .transparent-background {
        background-color: transparent;
        border: 0px;

        &.icon-settings {
            margin-left: 0px !important;

            span {
                color: $color-secondary
            }
        }
    }

    .option {
        position: absolute;
        width: 137px;
        top: 20px;
        right: 0;
        z-index: 99;

        .btn {
            padding: 15px;
            letter-spacing: 2px;
            width: 100%;
            text-transform: capitalize;
            text-align: left;
            display: table-cell;
        }

        .option-button {
            background-color: #fff;
            color: #000;
            transition: 0.2s;

            &:hover {
                background-color: rgba($color: $color-checkbox, $alpha: .1);
                color: $color-checkbox;

            }
        }
    }
}
