.toaster {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -125%);
  background: rgba(0, 0, 0, 0.6);
  color: $color-white;
  padding: 8px 20px;
  border-radius: 2px;
  animation: toasted 0.4s ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
 	z-index: 9999;

  & button {
    margin-left: 25px;
  }
}

@keyframes toasted {
  from {
    opacity: 0;
    transform: translate(-50%, -125%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
