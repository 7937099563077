@import '../../App.scss';
@import '../../style/abstracts/variables';

.wrap-container-dashboard {
  height: calc(100vh - 192px);
}

.report-style-class {
  height: 69vh;
  margin: 1% auto;
  width: 60%;
}

.relative-potision {
  position: relative;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .download-container {
    position: relative;
    width: 100%;
    display: inline-flex;

    .custom-spinner-container {
      position: relative;
      width: fit-content;
      margin: 0 30px 30px 0;
    }

    .inner {
      width: 100%;
      position: relative;
      .margin-10 {
        margin: 0 10px;

        .expand {
          transform: rotate(180deg);
          transition: transform 0.2s linear;
        }

        .collapse {
          transform: rotate(0deg);
          transition: transform 0.2s linear;
        }
      }

      .container {
        .transparent-background {
          border-top: 1px solid #d5d5d5;
          border-bottom: 1px solid #d5d5d5;
        }
      }
    }
  }
}

.grid-2 {
  @extend .grid-3;
  grid-template-columns: 1fr 207.61px;
}

.container-subnav {
  // button {
  //     padding: 15px 20px !important;
  // }
}

.container-dashboard {
  position: relative;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
