@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.select-container {
  width: 100%;
  display: flex;
  position: relative;

  .select-title {
    font-weight: 500;
    font-size: 19px;
    opacity: 1;
    position: absolute;
    top: -27px;
    left: 0;
    color: #222 !important;
    letter-spacing: 0.4px;
  }

  .required {
    &::after {
      content: "";
      position: absolute;
      width: 9px !important;
      height: 9px !important;
      background-color: $color-error;
      border-radius: 50%;
      top: 0;
      right: -15px !important;
    }
  }

  .select-bg {
    font-size: 14px;
    border-radius: 0px;
    height: 49px;

    .MuiOutlinedInput-notchedOutline {
      border-color: #d6d6d6 !important;
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #d6d6d6;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-secondary !important;
        border-width: 1px !important;
      }
    }

    .MuiSelect-select {
      &:focus {
        background-color: #fff;
      }
    }

    .MuiSelect-icon {
      color: $color-secondary;
    }

    .custom-icon {
      transform: rotate(90deg);
      margin-right: 20px;
      background-color: transparent;
      color: transparent;
      pointer-events: none;
    }
  }

  .form-helper-text {
    font-size: 13px;
    font-weight: 400;
    color: $color-error;
  }
}
