input {
  letter-spacing: 0.4px;
  font-family: 'Roboto';

  &::placeholder {
    color: #bbb;
    opacity: 1;
  }
  
  &:-ms-input-placeholder,
  &::-ms-input-placeholder  {
    color: #bbb;
  }
}

.input-primary {
  @include form-ctrl-primary;
  line-height: 0px;

  &:focus {
    border-color: $color-secondary; 
  }

  &.size-small {
    width: 250px;

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  &.size-full {
    width: 100%;
  }

  &.size-half {
    width: 50%;
  }

  &.error {
    @include form-error;
    border: none;
  }
}

.error-message {
  color: $color-error;
  margin-top: 30px;
  font-size: 14px;
}

.select-primary {
  box-shadow: $shadow-secondary;
  @include form-ctrl-primary;

  -webkit-appearance: none;
  -moz-appearance: none;
  //background: #fff url(../images/icon-drop-down.svg) calc(100% - 12px) 18px no-repeat;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.7);
  }

  &:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.7);
  }

  &.size-small {
    width: 140px;
  }

  &.size-medium {
    width: 240px;
  }

  &.left {
    margin-left: 10px;
  }
}

// Hide IE styling
select::-ms-expand {
  display: none;
}

// Custom checkbox - https://codepen.io/takeradi/pen/rjJpKK
.checkbox {
  margin-right: 15px;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
}

.checkbox label.wizard::before {
  @include animate-fast;
  height: 70px;
  width: 180px;
  background: $color-white;
  border: 1px solid #cecece;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  position: relative;
  left: 0px;
  top: 3px;
}

.checkbox input[type="checkbox"]:checked + label::before {
  border: 1px solid $color-secondary;
  box-shadow: 0 2px 6px 0 rgba(31, 89, 248, 0.36);
  background: $color-white;
}

.checkbox input[type="checkbox"]:checked + label {
  & .logo-app-switch {
    color: $color-secondary;
    opacity: 1;
    filter: unset;
  }
}

// Checkbox Primary
input[type=checkbox].custom-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type=checkbox].custom-checkbox + label.css-label {
  @include animate-fast;
  padding-left: 40px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  cursor: pointer;
}

// input[type=checkbox].custom-checkbox:checked + label.css-label {
//   background-image: url(../images/checkbox-bkgrd-checked.svg);
// }

// label.css-label {
//   background-image: url(../images/checkbox-bkgrd.svg);
//   -webkit-touch-callout: none;
//   -ms-user-select: none;
//   user-select: none;
// }

// Custom Radios
.radio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
  width: 380px;
  height: 40px;

  & input[type="radio"] {
    display: none;

    &:checked {
      & + label {
        & .label-option {
          color: $color-secondary;
          font-weight: 600;
        }

        &:before {
          border: none;
          box-shadow: 0px 0px 0px 2px $color-secondary inset;
          background: $color-white;
          
        }
      }
    }
  }

  & label {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:hover {

      & .label-option {
        font-weight: 600;
      }

      &:before {
        box-shadow: 0px 0px 0px 1px $color-secondary inset;
        background: $color-white;        
      }
    }

    &:before {
      @include animate-fast; 
      position: relative;
      width: 100%;
      height: 40px;
      background: $color-white;      
      box-shadow: 0px 0px 0px 1px $color-secondary inset;
      border-radius: 3px;
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-block;
    }

    // IE Only
    &:nth-of-type(1) {
      -ms-grid-column: 1;
    }
    &:nth-of-type(2) {
      -ms-grid-column: 2;
    }
  }
}

// Radio text
.label-option {
  @include animate-fast;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 42px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.7px;
  color: $color-secondary;
}