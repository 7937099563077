@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.toaster-container {
    top: 5px !important;
    .MuiSnackbarContent-root {
        background-color: transparent;
        background: linear-gradient(45deg, $color-modal-header-1, $color-secondary);
        padding: 0 16px;
        min-height: 47px;

        .MuiSnackbarContent-message {
            .custom-toaster {
                display: flex;
                justify-content: center;
                align-items: center;
        
                .toaster-with-function {
                    display: grid;
                    grid-template-columns: 1fr 155px;
                    gap: 10px;
                    align-items: center;
                }
                
                .toaster-no-function {
                    display: grid;
                    grid-template-columns: 22px 1fr;
                    gap: 10px;
                    align-items: center;
            
                    .size-tiny {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
