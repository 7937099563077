@import '../abstracts/variables';

.spinner-bkgrd {
  @include grid-full-cover;
  background: $color-white;
  opacity: 0.9;
  position: absolute;
}

.spinner-container {
  @include vertical-horizontal-align;
  width: 100%;
  z-index: 9999999;
  position: absolute;
  text-align: center;
}

.spinner-large {
  border-bottom: 2px solid $color-secondary;
  border-left: 2px solid $color-secondary;
  border-right: 2px solid #e7dbff;
  border-top: 2px solid #e7dbff;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  display: inline-block;
  animation: spin 0.4s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $color-secondary;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
