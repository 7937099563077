header {
  display: flex;
  align-items: center;

  @include grid-side-space;
  width: 100%;
  background: $color-white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
  z-index: 999;
  position: relative;
}

.user-loggedin {
  display: flex;
  align-items: center;
  justify-self: end;
  margin-left: auto;
  font-size: 14px;
  color: $color-secondary;
  margin-right: 5px;

  & .icon-user {
    margin-right: 9px;
  }

  & .icon-settings {
    margin-left: 30px;
  }

  // Role type
  & > .badge {
    margin-left: 9px;
  }
}
