// Map
.container-map {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 700px 1fr;
}

// Hospital
.container-hospital {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 90px 1fr 0px 40px;

  &.with-modal {
    grid-template-rows: 60px 90px 1fr auto 40px;
  }
}

// Login
.container-login {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  background: url('../../images/login-bkgrd.jpg');
  background-size: cover;
  background-position: center;
}

// Dashboard
.container-dashboard {
  position: relative;
  margin-top: 20px;

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }

  & iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Subnav
.container-subnav {
  @include grid-side-space;
  display: flex;
  align-items: center;
  background: $color-light-grey;

  & .title {
    // Use next to button
    &.left {
      margin-left: 45px;
    }

    &.right {
      margin-left: auto;
      color: $color-secondary;
    }
  }
}
