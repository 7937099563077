footer {
    @include sub-header;
	color: #ddd;
    background: #4A4A4A;
	padding: 0 30px;
    margin-top: 40px;
	position: relative;
	
	ul {
		margin: 0;
		padding: 0;
		
		li {
			list-style-type: none;
			display: inline;
			margin-right: 20px;
			
			&:last-of-type {
				margin-right: 0;
			}
			
			a {
				color: #ddd;
				text-decoration: none;
				padding: 0 10px;
				
				&:hover {
					color: $color-tertiary;
				}
				
				@media (max-width: 1000px) {
					padding: 0;
				}
			}
		}
	}
	
	& > .left {
			float: left;
			width: 50%;
		
		@media (max-width: 1000px) {
			width: 100%;
			position: unset;
			top: unset;
			transform: unset;
			margin-bottom: 10px;
		}
	}
	& > .right {
			float: right;
		
			li {
				
				&:last-of-type {
					margin-bottom: 0;
				}
				
				@media (max-width: 450px) {
					display: list-item;
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
		
		@media (max-width: 1000px) {
			position: unset;
			top: unset;
			transform: unset;
			float: none;
		}
	}
	
	@media (max-width: 1000px) {
		height: auto;
		padding: 20px 35px;
	}
	
	@media (max-width: 600px) {
		padding: 20px;
	}
	
	@media (max-width: 450px) {
		padding: 20px 15px;
		color: #ddd;
	}
}