// Logo
.logo {
  width: 146px;
  height: 30px;
  background: url(../../images/uvconcepts-logo.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  // Retina screen image
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    width: 146px;
    height: 30px;
    background: url(../../images/uvconcepts-logo@2x.png);
    background-size: contain;
  }
}

.icon-small {
  width: 22px;
  height: auto;
}

.icon-xsmall {
  width: 18px;
  height: auto;
}
