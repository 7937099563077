@import "../../../App.scss";
@import "../../../style/components//spinner";
@import "../../../style/abstracts/variables";

.flex-container {
    display: flex;

    button {
        padding: 10px 20px;
    }
}

.container-subnav {
    position: relative;

    .uvc-admin-grand-title {
        color: $color-secondary;
        font-weight: 500;
        font-size: 18px;
    }
}

.container-dashboard {
    position: relative;
    width: 100%;
    height: calc(100vh - 220px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        height: 1px;
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient($color-secondary, $color-secondary);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    .no-facility {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: grid;
        grid-template-columns: 1fr 1fr;

        .img-container,
        .content-container {
            display: flex;
            justify-content: center;
            align-items: baseline;
        }

        .content-container {
            h1,
            h5 {
                color: $color-secondary;
            }
            h1 {
                font-weight: 100;
                letter-spacing: 2px;
                font-size: 30px;
            }
            h5 {
                margin-top: -10px;
            }

            .btn {
                padding: 15px;
                letter-spacing: 1px;
                margin: 30px 0;
            }
        }
    }

    .overflow-container {
        height: 100%;
        padding: 30px;

        .facility-info {
            width: 100%;
            border: 1px solid #d6d6d6;
            border-radius: 5px;
            background-color: transparent;
            transition: 0.2s;
            display: flex;
            justify-content: center;
            padding: 30px;
            position: relative;
            cursor: pointer;
            height: 100%;

            .action-bar {
                width: fit-content;
                position: absolute;
                top: 10px;
                right: 10px;
                display: inline;
                opacity: 0;
                visibility: hidden;
                transition: 0.2s;

                .action-button {
                    padding: 9px;
                    background-color: transparent;
                }
            }

            .inner {
                width: 100%;
                margin: 0 auto;
                position: relative;
                padding: 5px;

                .img {
                    display: block;
                    margin: auto;
                }

                .facility-title,
                .facility-address {
                    color: $color-secondary;
                    margin: 10px 0;
                    text-align: center;
                    line-height: 1.5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .facility-title {
                    font-size: 18px;
                    font-weight: 700;
                }

                .facility-address {
                    margin-bottom: 120px;
                    font-size: 15px;
                }

                .enclosures-info {
                    width: 100%;
                    min-height: 40px;
                    position: absolute;
                    bottom: 0;

                    .item {
                        display: grid;
                        grid-template-columns: 1fr 1fr 50px;
                        width: 100%;
                        align-items: center;
                        padding-bottom: 7px;
                        margin-bottom: 7px;
                        font-size: 14px;

                        h5 {
                            color: $color-secondary;
                            font-size: 12px;
                        }

                        .badge.size-small {
                            font-size: 13px;
                        }
                    }

                    .item:not(:last-child) {
                        border-bottom: 1px solid #d6d6d6;
                    }
                }
            }

            &:hover {
                border: 1px solid $color-secondary;
                background-color: rgba($color: $color-secondary, $alpha: 0.2);
                z-index: 1;

                .action-bar {
                    opacity: 1;
                    visibility: visible;
                    z-index: 2;

                    .action-button {
                        background-color: rgba($color: $color-secondary, $alpha: 0);
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            height: 1px;
            width: 1px;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }
}

.add-facility-modal {
    width: 100%;

    .delete-title,
    .delete-description {
        line-height: 1.5em;
    }

    .delete-title {
        font-size: 20px;
        font-weight: bold;
    }

    .delete-description {
        font-size: 16px;
        margin-top: 20px;
    }

    .require-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .title {
            color: $color-error;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            padding: 0 10px;
        }
    }

    .text-field-container {
        display: grid;
        gap: 20px;
        grid-template-areas:
            "one one one one"
            "two two two two"
            "three three three three"
            "four four five five"
            "six . . .";
        .grid-area-1 {
            grid-area: one;
        }
        .grid-area-2 {
            grid-area: two;
        }
        .grid-area-3 {
            grid-area: three;
        }
        .grid-area-4 {
            grid-area: four;
        }
        .grid-area-5 {
            grid-area: five;
        }
        .grid-area-6 {
            grid-area: six;
        }
    }

    .action-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        width: 100%;

        .btn {
            width: 100% !important;
        }

        .tertiary {
            background-color: #ebebeb;
            background-image: none;
            color: #000 !important;

            &:hover {
                background-color: gray;
                background-image: none;
            }
        }

        .error-color {
            background-color: $color-error;

            &:hover {
                background-color: rgba($color: $color-error, $alpha: 0.5);
            }
        }
    }

    .required {
        &::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: $color-error;
            border-radius: 50%;
            top: 0;
            right: 0;
        }
    }
}
