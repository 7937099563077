// Labels
.label-secondary {
  color: $color-secondary;
}
.label-tertiary {
  color: $color-tertiary;
}
.label-error {
  color: $color-error;
}
.label-success {
  color: $color-success;
}

// Badges
.badge {
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  background: $background-secondary;
  color: $color-secondary;

  &.size-small {
    padding: 5px 10px;
    font-size: 12px;
  }

  &.size-medium {
    padding: 6px 15px;
    font-weight: $font-bold;
  }
}

.wrap-2-lines {
  overflow: hidden;
  position: relative;
  background-color: inherit;
  height: 2.4em;

  span::after {
    content: "...";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5%;
    height: 1.2em;
    background-color: transparent;
  }
}
