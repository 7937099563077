@import "../App.scss";
@import "../style/abstracts/variables";


.session-expire-container {
  text-align: center;

  .remove-icon {
    margin: 17px 0;
  }

  .remove-title, .remove-message {
      margin-bottom: 10px;
      font-size: 14px;
  }

  .remove-message {
      font-size: 16px;
      color: $color-error;
  }
}
