@import "../../../style/abstracts/variables";

.container {
    max-height: 400px;
    overflow-y: auto;
    width: calc(100% - 20px);
    position: absolute;
    right: 0;
    top: 45px;
    background-color: #fff;
    border-radius: 3px;
    margin: 0 10px;
    z-index: 2;
    visibility: visible;
    opacity: 1;
    transition: transform 0.2s linear, opacity 0.2s linear;
    box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.3);

    & .transparent-background {
        background-color: #fff !important;
        width: 100%;
        height: 50px;
        color: #000;

        &:hover {
            background-color: $color-secondary !important;
            color: #fff;
        }
    }
}

.hidden {
    @extend .container;
    opacity: 0;
    visibility: hidden;
}
