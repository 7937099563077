@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.custom-footer {
    height: 40px;
    background-color: $color-secondary;
    z-index: 999;
    padding: 0 35px;
    margin: 0;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;

    h5 {
        font-size: 12px;
        strong {
            font-weight: bold;
        }
    }

    .external-links {
        display: inline-flex;

        .btn {
            text-transform: capitalize;
        }
    }
}

.custom-flex {
    flex: 1;
}
