@import "./style/vendors/reset";
@import "./style/vendors/animate";
@import "./style/abstracts/variables";
@import "./style/abstracts/mixins";
@import "./style/base/globals";
@import "./style/base/images";
@import "./style/base/animation"; 
@import "./style/base/typography"; 
@import "./style/components/buttons"; 
@import "./style/components/links";
@import "./style/components/forms";
@import "./style/components/tables"; 
@import "./style/components/labels";
@import "./style/components/spinner";
@import "./style/layout/footer";
@import "./style/layout/header";
@import "./style/layout/grid"; 
@import "./style/layout/navigation";
@import "./style/layout/messages";

.toaster-container {
  margin-bottom: 20px;
  z-index: 99999999;
  & > div {
    background-color: transparent;
    background: linear-gradient(45deg, $color-modal-header-1, $color-secondary);
    padding: 0 16px;
    min-height: 47px;
    margin-top: -20px;

    #notistack-snackbar {
      .custom-toaster {
        display: flex;
        justify-content: center;
        align-items: center;

        .toaster-with-function {
            display: grid;
            grid-template-columns: 1fr 155px;
            gap: 10px;
            align-items: center;
        }
        
        .toaster-no-function {
            display: grid;
            grid-template-columns: 22px 1fr;
            gap: 10px;
            align-items: center;
    
            .size-tiny {
                margin-left: 0;
            }
        }
      }
    }
  }
}
