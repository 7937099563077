@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.table-root {
    height: 100%;
    position: relative;
    overflow-y: auto;

    &.with-pagination {
        height: calc(100% - 60px);
    }

    &::-webkit-scrollbar {
        height: 1px;
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient($color-secondary, $color-secondary);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    .table-container {
        background-color: transparent;

        .MuiTableHead-root {
            .MuiTableRow-root.MuiTableRow-head {
                .MuiTableCell-root {
                    &:nth-child(1) {
                        max-width: 470px;
                        width: 470px;
                    }
                    &:nth-child(2) {
                        max-width: 353px;
                        width: 353px;
                    }
                    &:nth-child(3) {
                        max-width: 309px;
                        width: 309px;
                    }
                }
            }
        }

        .MuiTableBody-root {
            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }

        .table-header {
            color: $color-secondary !important;
            font-weight: 500;
            font-size: 14px;
            border-bottom: 1.5px solid #BA9DED;
            background-color: #fff !important;
        }

        .transparent-background {
            background-color: transparent;
            color: #000;
            font-size: 15px;
            letter-spacing: 0.31px;
        }

        .normal-cell {
            position: relative;
            text-align: left;
            
            & .MuiTableCell-root {
                color: #222 !important;
                letter-spacing: 0.5px;
                font-size: 15px;
            }
            .action-bar {
                opacity: 0;
                visibility: hidden;
                transition: 0.2s;
                float: right;

                .action-button {
                    background: none;
                    background-color: transparent;
                }
            }

            &:hover {
                background-color: #f5f5f5;
                .action-bar {
                    opacity: 1;
                    visibility: visible;
                }
            }

            & td {
                &:nth-child(1) {
                    max-width: 470px;
                    width: 470px;
                }
                &:nth-child(2) {
                    max-width: 353px;
                    width: 353px;
                }
                &:nth-child(3) {
                    max-width: 309px;
                    width: 309px;
                }
            }
        }

        .normal-cell,
        .disable-cell {
            height: 65px;
        }

        .disable-cell {
            td {
                color: rgba($color: #000000, $alpha: 0.3);
            }
        }

        .caplitalize {
            text-transform: capitalize;
        }

        .btn.tertiary {
            background: none;
            color: $color-secondary;
            font-weight: 700;
            position: relative;
            text-transform: capitalize;
            padding: 9px 0;

            &::after {
                content: "";
                border-bottom: 1px solid $color-secondary;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 5px;
            }
        }
    }
}

.pagination {
    box-shadow: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
    z-index: 1;

    .pagination-item {
        li:not(:last-child) {
            margin-right: 20px;

            .Mui-selected {
                background-color: rgba($color: $color-secondary, $alpha: 0.1);
                color: $color-secondary;
                font-weight: 900;
            }
        }
        li:nth-last-child(2),
        li:nth-last-child(1),
        li:nth-child(1),
        li:nth-child(2) {
            button {
                color: $color-secondary;
            }

            .Mui-disabled {
                color: $color-grey !important;
            }
        }

        li:nth-last-child(1) {
            button {
                svg {
                    display: none;
                }

                &::after {
                    content: "Last";
                    font-weight: bold;
                }
            }
        }

        li:first-child {
            button {
                svg {
                    display: none;
                }

                &::after {
                    content: "First";
                    font-weight: bold;
                }
            }
        }
    }
}
