@import "../../../App.scss";
@import "../../../style/components//spinner";
@import "../../../style/abstracts/variables";


.enclosure-logs-container {
  display: grid !important;
  grid-template-columns: auto auto 1fr auto;

  .btn.back-button {
    margin: 0 40px 0 0;
    background: none;
    background-color: transparent;

    &:active, &:hover {
      background: none;
      background-color: transparent;
    }
  }

  .uvc-admin-grand-title {
    font-weight: 500;
  }
}

.container-dashboard {
  height: calc(100% - 20px);

  .inner {
    padding: 17px 50px;
    height: 100%;

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: $color-secondary;
    }

    .logs-grid-container {
      display: grid;
      grid-template-columns: 328px 1fr;
      grid-auto-rows: 100%;
      align-items: flex-start;
      height: 100%;

      .logs-grid-item {
        padding: 40px;
        height: 100%;
        position: relative;

        h4 {
          &.facility-title {
            max-width: 700px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 15px;
          }
          &.location {
            margin-left: -20px;
          }
        }

        .search-container {
          margin-bottom: 20px;
          position: relative;
          width: 300px;

          img {
              position: absolute;
              top: 50%;
              right: 5px;
              transform: translateY(-50%);
              z-index: 3;
              pointer-events: none;
          }

          .search-bar {
              padding: 10px;
              position: relative;
              z-index: 2;
              width: 300px;
          }
        }

        &:first-child {
          border-right: 1px solid #E4E4E4;
          padding: 0 0 0 40px;
        }
        
        .facilities-name-list {
          max-height: calc(100% - 57px);
          padding-right: 40px;
          overflow-y: auto;

          .facilities-name-item {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 40px;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            padding: 5px 10px;
            transition: .2s;

            h5 {
              color: $color-secondary;
              margin: 10px 0;
              text-align: left;
              line-height: 1.5em;
              text-overflow: ellipsis; 
              overflow: hidden;
              white-space: nowrap;
              font-weight: 400;
              font-size: 14px;
            }

            .badge.size-small {
              background-color: #EBDFFF;
            }

            &:hover, &.active {
              background-color: #F5EFFF;
              transition: .2s;
              cursor: pointer;

              .badge.size-small {
                background-color: #fff;
              }
            }
          }

          &::-webkit-scrollbar {
            height: 1px;
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
              background: linear-gradient($color-secondary, $color-secondary);
              border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
              background: transparent;
          }
        }

        .enclosure-logs {
          height: calc(100% - 17px);
          overflow-y: auto;
          position: relative;

          .enclosure-logs-title {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            .accordion-title {
              display: flex;
              align-items: center;
            }
            span > :first-child {
              margin-right: 10px;
            }
            span:nth-child(2) {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 200px;
            }
            .badge.size-small {
              font-size: 13px;
              font-weight: 400;
              line-height: 22px;
              padding: 5px 10px;
              max-width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          h5 {
            font-size: 15px;
            font-weight: 400;
            color: $color-secondary;
          }

          .empty-logs {
            color: #000;
            font-size: 15px;
            font-weight: 400;
            margin: auto;
          }

          .additional-info {
            max-width: 200px;
            h5 {
              text-align: right;
              margin-right: 10px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          &::-webkit-scrollbar {
            height: 1px;
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
              background: linear-gradient($color-secondary, $color-secondary);
              border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
              background: transparent;
          }

          .table-root {
            .table-container {
              .table-header {
                border-bottom: 2px solid #E5D6FF;
              }
            }
          }
          
        }

        .no-enclosure-logs {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .message-container {
            text-align: center;

            img {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}
