@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.modal-bg,
.modal-container {
    transition: 0.2s;
}

.modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
    z-index: 1000;
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 550px;
    background-color: #fff;
    -webkit-box-shadow: 0px 9px 20px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 9px 20px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 9px 20px 5px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    border-radius: 5px;
    overflow: hidden;

    .modal-header {
        width: 100%;
        height: 80px;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 40px;
        background: linear-gradient(45deg, $color-modal-header-1, $color-secondary);
        align-items: flex-end;

        .title {
            font-weight: 300;
            letter-spacing: 2px;
            font-size: 22px;
            color: #fff;
        }

        .transparent {
            background-color: transparent !important;
            padding: 0px;
            width: fit-content;
            justify-self: flex-end;

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    .modal-content {
        padding: 25px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 200px);
        height: 100%;

        &::-webkit-scrollbar {
            height: 1px;
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: linear-gradient($color-secondary, $color-secondary);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }

    .modal-action {
        height: 80px;
        padding: 20px;
        width: 100%;
        justify-content: center;
        display: flex;
        position: relative;
        margin-bottom: 10px;
    }

    .margin-top {
        margin-top: -60px;
        background-color: #fff;
    }
}
