* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: $font;
  letter-spacing: 0.4px;
  color: $color-primary;
  -webkit-font-smoothing: antialiased;
}
