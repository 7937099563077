@import "../App.scss";
@import "../style/abstracts/variables";

.container-subnav {
    align-items: center;
}

.special-loading {
    top: 60px !important;
    bottom: 40px !important;
}

.sub-grid-container {
    display: grid;
    grid-template-columns: 120px 1fr;
    max-height: calc(100vh - 190px);

    .sidebar {
        width: 100%;
        border-right: 1px solid #d6d6d6;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 190px);

        &::-webkit-scrollbar {
            height: 1px;
            width: 3px;
          }
    
          &::-webkit-scrollbar-thumb {
              background: linear-gradient($color-secondary, $color-secondary);
              border-radius: 10px;
          }
    
          &::-webkit-scrollbar-track {
              background: transparent;
          }
          
        .sidebar-item {
            width: inherit;
            height: 90px;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            text-align: center;
            cursor: pointer;

            .inner {
                width: 100%;
                padding: 10px;
                opacity: 0.5;
                background-color: transparent;

                &:hover {
                    opacity: 1;
                    background-color: rgba($color: $color-secondary, $alpha: 0.1);
                }
            }

            .active {
                @extend .inner;
                opacity: 1;
                background-color: rgba($color: $color-secondary, $alpha: 0.1);
            }

            h6 {
                color: $color-secondary;
                font-size: 13px;
                margin-top: 10px;
            }
        }
    }
}
