@import "../../../App.scss";
@import "../../../style/abstracts/variables";
@import "../../../style/components/spinner";

.spinner-bkgrd {
    position: absolute;
    opacity: 1 !important;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #fff;

    h3 {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        font-weight: bold;
        font-size: 18px;
    }

    .dot-loading-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;

        .dot-loading {
            position: relative;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $color-secondary;
            color: $color-secondary;
            animation: dotFlashing 1s infinite linear alternate;
            animation-delay: 0.5s;
        }

        .dot-loading::before,
        .dot-loading::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
        }

        .dot-loading::before {
            left: -25px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $color-secondary;
            color: $color-secondary;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 0s;
        }

        .dot-loading::after {
            left: 25px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $color-secondary;
            color: $color-secondary;
            animation: dotFlashing 1s infinite alternate;
            animation-delay: 1s;
        }
    }
}
