@import '../../App.scss';


  main {
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .reset-password-form {
      max-width: 441px;
      min-height: 365px;
      width: 100%;
      padding: 35px;
      background: $color-white;
      box-shadow: 0 55px 80px 0 rgba(0, 55, 75, 0.45);
      @media screen and (max-width: 629px) {
        width: 100%;
      }

      &.center-content {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }

      h3 {
        color: $color-secondary;
        font-size: 18px;
        font-weight: $font-boldest;
        display: flex;
        justify-content: center;
      }

      .desc {
        margin-top: 10px;
        text-align: center;
        span {
          color: $color-black;
          font-weight: $font-normal;
          line-height: 22px;
          font-size: 15px;
          width: 511px;
          letter-spacing: 0.31px;

          @media screen and (max-width: 629px) {
            width: 80%;
            display: inline-block;
          }
        }
      }

      .required {
        margin: 26px 35px 5px;
        display: flex;
        justify-content: flex-end;
        span {
          &:first-child {
            font-size: 14px;
            color: $color-error;
            font-weight: $font-normal;
          }
          &:last-child {
            background-color: $color-error;
            width: 7px;
            height: 7px;
            border-radius: 999px;
            margin-left: 6px;
          }
        }
      }

      .field-column {
        margin-top: 50px;
        .input-container {
          margin-bottom: 25px;
        }
      }

      .btn-container {
        position: relative;
        padding: 15px 0px;
        div {
          position: relative;
          top: 5px;
          .custom-spinner {
            left: 0px;
          }
        }
        button {
          width: 100%;
          padding: 15px 0px;
          border: none;
          outline: none;
          background-color: $color-secondary;
          color: $color-white;
          font-weight: $font-bold;
          font-size: 14px;
          font-family: $font;
          border-radius: 2px;
        }
      }
    }
  }

.required {
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: $color-error;
    border-radius: 50%;
    top: 0;
    right: 0;
  }
}
