// Colors
$background-primary: #f5f5f5;
$background-secondary: #f3eef9;
$color-primary: #444;
$color-secondary: #624299;
$color-medium-secondary: #543294;
$color-tertiary: #ff8905;
$color-quaternary: #8657d7;
$color-success: #2dc06a;
$color-error: #ec4141;
$color-warning: #ff9d3c;
$color-white: #fff;
$color-black: #222222;
$color-grey: #7d7d7d;
$color-medium-grey: #aaa;
$color-dark-grey: #484848;
$color-light-grey: #ebebeb;
$color-modal-header-1: #2b66ac;
$color-checkbox: #00a7e3;

// Fonts
$font: 'Roboto', sans-serif;
$font-normal: normal;
$font-bold: 500;
$font-bolder: 600;
$font-boldest: 700;

// Spacing
$padding-primary: 25px;

// Effects
$shadow-secondary: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
