@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.content {
    padding: 37px 50px 50px 50px;
    max-height: calc(100vh - 190px);

    .details-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 32px 1fr;
        gap: 10px;
        position: relative !important;

        &#operators {
            grid-template-rows: 20px 60px 1fr;
        }

        .outer-title {
            font-size: 18px;
            font-weight: 500;
            color: $color-secondary;
            letter-spacing: 1.5px;
            margin-bottom: 15px;
        }

        .details-header {
            display: grid;
            grid-template-columns: auto 1fr auto;
            margin-bottom: 40px;
            align-items: center;
            height: 100%;

            .search-container {
                position: relative;

                img {
                    position: absolute;
                    top: 57%;
                    right: 5px;
                    transform: translateY(-50%);
                    z-index: 3;
                    pointer-events: none;
                }

                .search-bar {
                    padding: 10px;
                    position: relative;
                    z-index: 2;
                    width: 300px;
                }
            }

            .title {
                font-size: 18px;
                color: $color-secondary;
                letter-spacing: 1.5px;
                font-weight: 500;
            }

            .add-operators {
                height: 32px;
            }
        }

        .enclosures-content,
        .departments-content,
        .users-content,
        .operators-content {
            margin-top: 10px;
        }

        .operators-content {
            table {
                td {
                    div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    }
                }
            }
        }

        .enclosures-content {
            overflow-y: auto;
            height: 100%;

            &::-webkit-scrollbar {
                height: 1px;
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: linear-gradient($color-secondary, $color-secondary);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .enclosure {
                width: 100%;
                height: 250px;
                border-radius: 5px;
                border: 1px solid #d6d6d6;
                overflow: hidden;
                padding: 20px;
                position: relative;
                transition: 0.2s;
                z-index: 1;

                .nickname-container {
                    display: grid;
                    grid-template-columns: auto auto auto 1fr auto;
                    gap: 10px;
                    align-items: center;
                    padding: 10px 5px 0;

                    .nickname {
                        font-size: 16px;
                        font-weight: bold;
                        color: $color-secondary;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .action-buttons {
                        opacity: 0;
                        transition: 0.2s;

                        .size-small {
                            background: none;
                        }
                    }

                    .edit-software-action {
                        opacity: 1;
                        margin-left: -15px;
                    }
                }

                .property-container {
                    margin: 10px 0;
                    width: 100%;

                    .property-item {
                        height: 55px;
                        width: 100%;
                        h5,
                        h6 {
                            margin: 5px 0;
                            line-height: 1.5em;
                            font-size: 15px;
                            height: 3em;
                            color: #222;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            text-align: left;
                            font-weight: 400;
                            white-space: nowrap;
                        }

                        h5 {
                            font-weight: bold;
                            color: $color-quaternary;
                            font-weight: 500;
                            font-size: 13px;
                            height: 1.5em;
                        }
                    }
                }

                .offline-container {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: fit-content;
                    z-index: 2;

                    .inner {
                        width: 100%;
                        height: 100%;
                        background-color: $color-error;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px;

                        h6 {
                            color: #fff;
                            font-size: 14px;
                            text-align: center;
                            line-height: 1.5em;

                            img {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                &:hover {
                    border: 1px solid $color-secondary;
                    background-color: rgba($color: $color-secondary, $alpha: 0.1);

                    .nickname-container {
                        .action-buttons {
                            opacity: 1;
                            transition: 0.2s;
                        }
                    }
                }

            }
        }

        .departments-content {
            height: 100%;
            overflow-y: auto;

            .departments-grid-container {
                height: 100%;

                .departments-grid-item {
                    height: 100%;
                    
                    &:first-child {
                        border-right: 1px solid #f5f5f5;
                    }

                    .departments-title-list {
                        padding: 10px 50px 10px 0;
                        height: calc(100% - 40px);
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            height: 1px;
                            width: 3px;
                        }
            
                        &::-webkit-scrollbar-thumb {
                            background: linear-gradient($color-secondary, $color-secondary);
                            border-radius: 10px;
                        }
            
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        .item {
                            width: 100%;
                            height: 40px;
                            border-radius: 5px;
                            margin: 10px 0;
                            background-color: transparent;
                            color: $color-secondary;
                            transition: 0.2s;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            padding: 10px;
                            font-weight: 400;
                            
                            h5 {
                                font-size: 15px;
                                font-weight: 400;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            &:hover {
                                background-color: rgba($color: $color-secondary, $alpha: 0.1);
                            }

                            &.active {
                                background-color: rgba($color: $color-secondary, $alpha: 0.1);
                            }
                        }
                    }
                }
            }
        }

        .equipments-content {
            margin-top: 23px;
            overflow-y: auto;
            .equipment-style {
                width: 60%;
                    h4 {
                    max-width: 350px;
                    @media (max-width: 1024px) {
                        max-width: 30%;
                    }

                    @media (max-width: 1280px) {
                        max-width: 40%;
                    }
                }
            }
            &::-webkit-scrollbar {
                height: 1px;
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: linear-gradient($color-secondary, $color-secondary);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .summary-container-additional-info {
                display: flex;
                align-items: center;
                text-align: left;

                .cycle-title {
                    color: $color-secondary;
                    font-weight: 400;
                }

                .badge.transparent {
                    font-size: 13px;
                    width: 90px;
                }

                .btn.transparent {
                    background-color: transparent;
                    background: none;

                    &:disabled {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

            .normal-cell {
                .cell-item {
                    position: relative;
                    max-width: 460px;
                    .btn.transparent.delete-equipment {
                        float: right;
                        background: transparent;
                        margin-right: 5px;
                        position: relative;
                        opacity: 0;
                        visibility: hidden;
                        transition: .2s;
                    }
                }
                
                &:hover {
                    .cell-item > .btn.transparent.delete-equipment {
                        opacity: 1;
                        visibility: visible;
                        transition: .2s;
                    }
                }
                .empty-equipment {
                    font-size: 15px;
                    margin: auto;
                }
            }
        }

        .equipments-search-content {
            @extend .equipments-content;
            position: relative !important;
            
            .empty-search-container {
                width: 100%;
                height: 100%;
                position: relative;

                .empty-equipment-searching {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    font-size: 16px;
                    line-height: 1.5em;
                }
            }
        }

        .users-content {
            width: inherit;
            height: 100%;
            overflow-y: auto;
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 4px;
                width: 1px;
            }
    
            &::-webkit-scrollbar-thumb {
                background: linear-gradient($color-secondary, $color-secondary);
                border-radius: 10px;
            }
    
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .with-pagination {
                min-width: 1300px;


                .normal-cell, .disable-cell {
                    position: relative;
    
                    td > .action-bar {
                        float: right;
    
                        .btn.primary {
                            background: none;
                            background-color: transparent;
                            transition: .2s;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
    
                    &:hover {
                        td > .action-bar {
                            .btn.primary {
                                opacity: 1;
                                visibility: visible;
                                transition: .2s;
                            }
                        }
                    }
                }
            }

            .pagination {
                position: absolute;
                top: calc(100% - 60px);
            }
        }

        .operators-content {
            height: 100%;
            overflow-y: auto;
            // position: relative;

            .operator-title {
                width: 70%;
                @media (max-width: 1280px) {
                    max-width: 50%;
                }
            }

            &::-webkit-scrollbar {
                height: 1px;
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: linear-gradient($color-secondary, $color-secondary);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .table-root {
                .table-container {
                  .table-header {
                    border-bottom: 2px solid #E5D6FF;
                  }
                }
            }
        }

        .operators-search-content {
            @extend .operators-content;
            position: relative !important;

            .empty-operator {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 16px;
                line-height: 1.5em;
            }
        }

        .no-details {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            grid-template-columns: 200px 1fr;

            .img-container,
            .content-container {
                display: flex;
                justify-content: center;
                align-items: baseline;

                &.without-button {
                    align-items: center;
                }
            }

            .content-container {
                h1,
                h5 {
                    color: $color-secondary;
                }
                h1 {
                    font-weight: 300;
                    letter-spacing: 2px;
                    font-size: 36px;
                }
                h5 {
                    margin-top: -10px;
                    font-weight: 400;
                    font-size: 16px;
                }

                .btn {
                    padding: 15px;
                    letter-spacing: 1px;
                    margin: 30px 0;
                }
            }

        }
    }

    .details-container-for-equipment {
        @extend .details-container;
        padding: 0 0 0 50px;
        grid-template-rows: 20px 20px 60px 1fr;
        &::-webkit-scrollbar {
            height: 1px;
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: linear-gradient($color-secondary, $color-secondary);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        .outer-title {
            width: 100%;
            display: flex;
            height: 30px;
            span:nth-child(1) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 990px;
            }
            span:nth-child(2) {
                margin-left: 20px;
                margin-top: -6px;
                .action-bar {
                    transition: 0.2s;
                    display: inline-flex;

                    .action-button {
                        background: none;
                        background-color: transparent;
                        padding: 9px;
                    }
                }
            }
        }

        .margin-top {
            padding-top: 30px;
        }

        .header-equipment {
            margin-top: 20px;
        }

        .with-bulk-upload {
            grid-template-columns: auto 1fr auto auto;
            gap: 20px;
            margin-top: 20px;

            .option {
                width: 200px;
                margin: 0 2px 0 0;
                
                .transparent-background {
                    display: table-cell;
                    text-align: left;
                    color: #222;
                    font-size: 14px;
                    font-weight: 400;

                    &:hover {
                        background-color: rgba($color: $color-checkbox, $alpha: .1) !important;
                        color: $color-checkbox;
                    }
                }
            }
        }

        .table-root {
            .table-container {
              .table-header {
                border-bottom: 2px solid #E5D6FF;
              }
            }
          }
    }

    .edit-version-modal, 
    .edit-enclosure-modal, 
    .edit-user-modal, 
    .edit-department-modal, 
    .edit-equipment-modal,
    .edit-operator-modal {
        width: 100%;
        .require-title-container {
            margin: -20px 0 15px 0;
            
            .require-title {
                position: relative;
                width: fit-content;
                padding-right: 10px;
                color: $color-error;
                float: right;
            }
        }

        .checkbox-root {
            display: flex;
            align-items: flex-start;

            .custom-checkbox {
                padding: 0px 9px 9px 9px;
                
                &.Mui-checked {
                    color: $color-checkbox;
                }
            }

            .MuiTypography-root {
                font-size: 14px;
                line-height: 19px;
                font-weight: 400;
            }
        }

        .config-selection {
            margin-top: 29px;

            label {
                top: -30px;
                font-weight: 500;
            }

            .select-bg {
                height: 50px;

                .MuiSelect-root {
                    padding: 15px !important;
                }
            }

            &.user-role {
                margin-top: 20px;
                .select-bg {
                    height: 50px;
                }
               
            }
        }

        .action-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            width: 100%;
            height: 47px;
    
            .btn {
                width: 100% !important;

                &.delete-button {
                    background-color: $color-error;
                }
            }
    
            .error-color {
                background-color: $color-error;
    
                &:hover {
                    background-color: rgba($color: $color-error, $alpha: 0.5);
                }
            }
        }

        .margin-40 {
            margin-top: 40px;
        }

        .required {
            &::after {
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                background-color: $color-error;
                border-radius: 50%;
                top: 0;
                right: 0;
            }
        }

        input:disabled {
            color: rgba($color: #000, $alpha: .26);
            cursor: not-allowed;
        }

        .remove-container {
            text-align: center;

            .remove-icon {
                margin: 17px 0;
            }

            .remove-title, .remove-message {
                margin-bottom: 10px;
                font-size: 14px;
            }

            .remove-message {
                font-size: 16px;
                color: $color-error;
            }
        }

        .bulk-uploader-container {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid #E0E0E0;

            .download-template {
                background-color: transparent;
                text-transform: capitalize;
                text-decoration: underline;
                margin: 15px 0;
                color: $color-secondary;
                font-size: 14px;
                font-weight: 400;
                padding: 6px 0;
            }

            .bulk-upload-note-container > p {
                color: #7F7F7F;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 15px;
            }

            .upload-file {
                margin: 15px 0;
                display: grid;
                grid-template-columns: 120px 1fr;
                gap: 20px;
                align-items: center;

                .size-normal {
                    &.disabled {
                        background: rgba($color: gray, $alpha: .9) !important;
                        pointer-events: none;
                    }
                }

                input[type="file"] {
                    display: none;
                }

                .path-name {
                    span {
                        margin: 0 10px;
                    }
                }
            }
        }
    }

    .edit-department-modal {
        .custom-switch-container {
            margin-top: 30px;
        }
    }

    .edit-version-modal {  
        .current-version {
            padding-bottom: 10px;
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 40px;
            span {
                font-size: 14px;
                display: block;
                width: fit-content;
                font-weight: 500;
                margin: 10px 0;

                &:first-child {
                    font-weight: bold;
                    color: $color-secondary;
                    font-size: 16px;
                    margin-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 415px;
                }
            }

            .badge.size-medium {
                font-size: 13px;
                margin: 10px 0;
            }
        }
        .software-selection-grid-container {

            & .select-title {
                width: 300px;

                &::after {
                    right: 35px !important
                }
            }
        }
    }

    .edit-enclosure-modal {
        .password-not-match {
            color: $color-error;
        }

        .divider {
            border-bottom: 1px solid #e0e0e0;
            margin: 30px 0;
        }

        .btn.reset-password {
            margin: 15px 0;
            background: none;
            background-color: transparent;
            line-height: 1.5em;
            text-decoration: underline;
            color: $color-secondary;
            padding: 0px;
            font-weight: bold;
            text-transform: capitalize;
            font-size: 14px;

            &:hover, &:active {
                background-color: transparent !important;
                background: none !important;
            }
        }

        .reset-password-container {
            margin-top: 5px;
        }

        .software-container {
            margin: 15px 0;
            padding: 40px 0 10px 0;
            border-top: 1px solid #E0E0E0;
            border-bottom: 1px solid #E0E0E0;

            .software-selection-container {
                margin-top: 30px;

                label {
                    width: 300px;                    

                    &::after {
                        right: 35px !important
                    }
                }
            }
        } 
    }

    .edit-user-modal {
        .textfield-container {
            margin-bottom: 20px;
        }

        .MuiGrid-spacing-xs-2 > .MuiGrid-item:nth-of-type(2) {
            padding-top: 17px
        }
    }

    .edit-operator-modal {
        h5 {
            font-size: 14px;
            font-weight: 500;
            color: #222;
            position: relative;
            width: fit-content;
            padding: 0 10px 0 0;
        }
        .single-operator, .bulk-operators {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            border: 1px solid #d5d5d5;
            border-radius: 4px;
            height: 213px;
            width: 192px;
            margin: 0 auto;
            transition: .2s;

            &:hover {
                border: 1px solid $color-secondary;
                background-color: rgba($color: $color-secondary, $alpha: 0.15);
                cursor: pointer;
            }

            div {
                text-align: center;
                h6 {
                    margin-top: 20px;
                    color: $color-secondary;
                    line-height: 1.5em;

                    strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .help-container {
            h5 {
                margin: 20px 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5em;
                span {
                    color: $color-checkbox;
                    font-weight: 500;
                }
            }

            &.margin-60 {
                margin-top: 65px;
            }
        }

        .single-action, .multiple-actions {
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 40px;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 47px;

            .size-normal {
                padding: 15px 10px;
            }
        }
    }

    .edit-equipment-modal {
        h5 {
            font-size: 14px;
            font-weight: 500;
            color: #222;
            position: relative;
            width: fit-content;
            padding: 0 10px 0 0;
        }

        .single-operator, .bulk-operators {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            border: 1px solid #d5d5d5;
            border-radius: 4px;
            height: 213px;
            width: 192px;
            margin: 0 auto;
            transition: .2s;

            &:hover {
                border: 1px solid $color-secondary;
                background-color: rgba($color: $color-secondary, $alpha: 0.15);
                cursor: pointer;
            }

            div {
                text-align: center;
                h6 {
                    margin-top: 20px;
                    color: $color-secondary;
                    line-height: 1.5em;

                    strong {
                        font-weight: bold;
                    }
                }
            }
        }

        .single-action {
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 40px;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 47px;

            .size-normal {
                padding: 15px 10px;
            }
        }
        .multiple-actions {
            grid-template-columns: 1fr 1fr 1.5fr;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 47px;
        }
        .equipment-type-container {
            margin-top: 20px;
            display: inline-block;
            width: 100%;

            h6 {
                font-size: 15px;
                font-weight: 400;
                margin-top: 8px;
            }

            .equipment-type-list {
                margin: 8px -8px 0 -8px;

                .equipment-type-item {
                    width: 100%;
                    height: 60px;
                    text-transform: capitalize;
                    line-height: 19px;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: transparent;
                    color: $color-quaternary;
                    border-radius: 2px;
                    border: 1px solid #DBC6FF;
                    
                    h4 {
                        text-align: center;
                        line-height: 1.5em;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    &:hover, &.active {
                        border: 1px solid $color-secondary;
                        color: $color-secondary;
                        background-color: #F5EFFF;
                        font-weight: 500;
                    }
                }
            }

            .add-equipment-type-container {
                width: 100%;
                display: flex;
                align-items: center;
                height: 44px;
                margin-top: 10px;

                .btn.transparent {
                    background-color: transparent;
                    background: none;
                    padding: 9px 0;

                    h6 {
                        color: $color-secondary;
                        font-size: 14px;
                        padding: 0 5px;
                        margin-top: 0px;
                    }
    
                    &:hover {
                        background-color: transparent;
                        background: none;
                    }
                }

                .add-type-inner-container {
                    width: 100%;
                    height: inherit;
                    position: relative;

                    .add-type-textfield {
                        margin-top: -50px;
                        padding-right: 80px;

                        &.full-width {
                            padding-right: 15px;
                        }
                    }

                    .input-container {
                        .error-title {
                            font-size: 13px !important;
                        }
                    }

                    .cta-type {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);

                        .cta-icon {
                            color: $color-secondary;
                            margin: 0 5px;
                        }
                    }
                }
            }

            .btn.transparent {
                background: transparent;
                margin: 0 5px;
            }
        }

        .cycle-freq-container {
            margin: 40px 0 30px 0;
            position: relative;

            h5 {
                margin-bottom: 15px;
            }

            .cycle-freq-inner {
                display: grid;
                grid-template-columns: 60px 1fr;
                gap: 15px;
                justify-content: center;
                align-items: center;

                .cycle-freq-textfield-container {
                    width: 100%;
                    position: relative;

                    .input-container {
                        display: flex;
                        align-items: center;

                        .cycle-freq-textfield {
                            margin-top: -5px;
                            width: 100%;
                            max-width: 135px;
    
                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                margin: 0
                            }
    
                            &[type=number] {
                                -moz-appearance: textfield;
                            }
                        }

                        .error-title {
                            margin: -5px 0 0 5px;
                        }
                    }

                    .days {
                        position: absolute;
                        width: 200px;
                        right: 5px;
                        left: 80px;
                        top: 50%;
                        transform: translateY(-60%);
                        color: #9A9A9A;
                        font-weight: 400;
                        display: flex;
                        align-items: center;

                        &.black {
                            color: #000;
                            left: 0px;
                            strong {
                                font-weight: 500;
                                padding-left: 5px;
                            }

                            .btn.edit {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .cycle-freq-note {
                padding: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-top: 5px;

                span {
                    color: #9A9A9A;
                    &:first-child {
                        color: $color-checkbox;
                        font-weight: 500;
                    }
                }
            }
        }

        .new-equipment-info {
            margin-top: 40px;
            position: relative;

            &::before {
                content: "";
                border-bottom: 1px solid #E0E0E0;
                width: 100%;
                position: absolute;
                top: -15px;
            }            
        }
        
        .bulk-help {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid #E0E0E0;
            width: 100%;
            display: grid !important;
            grid-template-columns: auto auto 1fr;
            gap: 5px;
            align-items: center;
        }

        .help-container {
            display: inline-flex;
        }

        .delete-type-container {
            display: inline-flex;
            align-items: center;
            .btn.delete-warning {
                background: transparent;
                color: $color-error;
                border: 1px solid $color-error; 
            }
            .delete-confirmation {
                margin-left: 10px;
                display: inline-flex;
                align-items: center;

                .remove-icon {
                    margin-right: 5px;
                }

                .btn {
                    margin: 0 5px;
                }

                .btn.tertiary {
                    background-color: #ebebeb;
                    background-image: none;
                    color: #000 !important;

                    &:hover {
                        background-color: gray;
                        background-image: none;
                    }
                }

                .btn.primary {
                    background-color: $color-error;
                    background-image: none;
                    color: #fff !important;
                }
            }
        }

            .single-action {
                grid-template-columns: 1fr;
                width: 40%;
                margin: auto;
            }
        
    }
}

.facility-detail-header-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr 100px;
    align-items: center;

    .facility-title-container {
        display: flex;

        .facility-title,
        .facility-address {
            margin: 5px 20px;
            color: $color-secondary;
            max-width: 600px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .facility-title-inner-container {
            display: flex;
            align-items: center;
            max-width: 600px;
            .facility-title {
                font-size: 18px;
                font-weight: bold;
                max-width: 580px;
                margin: 5px 10px 5px 20px;
            }
            .action-button {
                padding: 9px;
                background-color: transparent;
                background-image: none;
            }
        }

        .back-button {
            background-color: transparent !important;
            background-image: none !important;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.tooltip {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 0px !important;
}
