// Grid
@mixin grid-side-space {
  padding: 0 35px;
}

@mixin grid-space {
  padding: 35px;
}

@mixin grid-full-cover {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1399;
}

@mixin vertical-horizontal-align {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Headers
@mixin sub-header {
  width: 100%;
  height: 60px;
  float: left;
}

// Effects
@mixin animate-medium {
  transition: 0.6s ease;
}

@mixin animate-fast {
  transition: 0.3s ease;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Colors
@mixin background-content {
  background-image: linear-gradient(-180deg, #fff 55%, #f6f9ff 100%);
}

// Forms
@mixin form-ctrl-primary {
  padding: 14px 18px;
  border: 1px solid #cacaca;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}

@mixin form-error {
  box-shadow: inset 0 0 0px 1px rgb(254, 0, 0),
    0 1px 3px 0 rgba(231, 3, 3, 0.36);
}
