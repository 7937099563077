table {
  width: 100%;
  border-collapse: collapse;
}

.table-primary {
  th {
    color: $color-white;
    background: $color-secondary;
    text-align: left;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: $font-bold;
    cursor: default;
  }

  tr {
    @include animate-medium;

    &:hover {
      background: $background-primary;
      cursor: pointer;
    }

    &:last-of-type {
      td {
        border: none;
      }
    }

    &:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
    }
  }

  td {
    padding: 12px 20px;
    border-bottom: 1px solid #ddd;
  }
}

.table-locations {
  td {
    // Center 1st and 2nd columns in body
    &:nth-of-type(1),
    &:nth-of-type(2) {
      text-align: center;
    }
  }

  th {
    // Center 1st and 2nd columns in head
    &:nth-of-type(1),
    &:nth-of-type(2) {
      text-align: center;
    }
  }
}
