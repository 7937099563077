nav {
	@include grid-side-space;
	@include sub-header;
	background: $color-white;
	box-shadow: $shadow-secondary;
	font-weight: $font-bold;
	position: sticky;
	position: -webkit-sticky;
	top: 70px;
	z-index: 99999999;
	white-space: nowrap;
    overflow-x: auto;
	overflow-y: hidden;
	
	ul {
		margin: 0;
		padding: 0;
		
		li {
			list-style-type: none;
			display: inline;
			margin-right: 50px;
			
			&:last-of-type {
				margin-right: 0;
			}
			
			a {
				color: #333;
				text-decoration: none;
				padding: 0 10px;
				
				&:hover {
					color: $color-secondary;
				}
				
				&.active {
					color: $color-secondary;
					cursor: default;
					position: relative;
					
					&:after {
						background: $color-secondary;
						position: absolute;
						bottom: -20px;
						left: 0;
						width: 100%;
						height: 3px;
						content: "";
						
						@media (max-width: 320px) {
							bottom: -15px;
							height: 3px;
						}
					}
				}
				
				@media (max-width: 700px) {
					padding: 0 5px;
				}
			}
			
			@media (max-width: 700px) {
				margin-right: 20px;
			}
		}
	}
	
	@media (max-width: 600px) {
		padding: 0 20px;
	}
	
	@media (max-width: 450px) {
		padding: 0 15px;
	}
	
	@media (max-width: 320px) {
		height: 50px;
		position: unset;
	}
}

// For Firefox - don't stick header
@-moz-document url-prefix() {
    nav {
        position: unset!important;
    }
}

.actions {
	margin-bottom: 10px;
	float: right;
}