@import "../../../App.scss";
@import "../../../style/abstracts/variables";

.accordion-root {
    margin-bottom: 5px;

    .accordion-summary-containerr {
        background-color: #F5EFFF;
        height: 50px !important;
        min-height: 50px !important;
        .MuiAccordionSummary-content {
            width: 100%;
        }
        .accordion-header-container {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .show-more-icon {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                transition: 0.2s;
            }

            .accordion-title {
                display: flex;
                align-items: center;
                font-size: 16px;
                margin-left: 30px;
                color: $color-secondary;
                font-weight: 500;
                line-height: 1.5em;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                display: block;
                @media (max-width: 1440px) {
                    max-width: 520px;
                }

                @media (max-width: 1280px) {
                    max-width: 400px;
                }
            }

            .badge {
                background-color: #fff;
                padding: 10px;
                margin: 0 20px;
                color: #000;
            }

            .additional-info {
                min-width: 100px;
                position: absolute;
                right: 0;
                padding: 5px;
            }
        }
    }

    .Mui-expanded {
        .accordion-header-container {
            .show-more-icon {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                transition: 0.2s;
            }
        }
    }

    .table-root .table-container .table-header {
        font-size: 14px;
        font-weight: 500;
    }

    .accordion-detail-containerr {
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;

        .empty-equipment {
            font-size: 15px;
            margin: auto;
        }
    }
}
